//   \,   Atomic Styles   ,/    //

.atomic-primary-butt {
  background-color: #18274e;
  color: white;
  border-radius: 6px;
  padding: 6px;

  min-width: 100px;
}
.atomic-secondary-butt {
  min-width: 100px;
  color: black;
  background-color: rgb(148, 148, 148);
  border: solid 2px #c8cbd0;
  border-radius: 6px;
  padding: 6px;
}
.atomic-dropdown-Toggle {
  min-width: 100px;
  color: rgba(0, 0, 0, 0.9);
  background-color: #fbfbfb;
  border: solid 1px #c8cbd0;
  border-radius: 6px;
}
.atomic-primary-drop {
  background-color: #18274e !important;
  color: white !important;
  border-radius: 6px !important;
  padding: 6px !important;

  min-width: 100px !important;
}
.atomic-secondary-drop {
  min-width: 100px !important;
  color: #8f9092 !important;
  background-color: white !important  ;
  border: solid 2px #c8cbd0 !important;
  border-radius: 6px !important;
  padding: 6px !important;
}
.atomic-card-primary {
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.015);
  -webkit-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.015);
  -moz-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.015);
  border: solid 1px rgba(0, 0, 0, 0.08);
  background-color: white;
}
.atomic-heading-primary {
  color: rgba(0, 0, 0, 0.75);
  font-weight: 600;
}
.atomic-subHeading-primary {
  color: rgba(0, 0, 0, 0.75);
}
.atomic-input-label {
  font-weight: thin;
  color: rgba(0, 0, 0, 0.7);
}
//    ^^  Atomic Styles  ^^     //

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
  font-family: "Dirooz-FD" !important;
}

.primary-color {
  color: #18274e !important;
}
.white-color {
  color: rgba(255, 255, 255, 1) !important;
}
.primary-background {
  background-color: rgba(255, 255, 255, 1) !important;
}
.primary-border {
  border: solid 2px rgba(24, 39, 78, 0.2);
}
.secondary-background {
  background-color: rgb(148, 148, 148);
}
.secondary-background {
  color: black;
}

.height-100vh {
  height: 100vh !important;
}
.sidebar-trigger-div {
  padding: 1px;
  width: fit-content;
  border: solid 2px white;
  border-radius: 6px;
  box-shadow: rgba(250, 250, 250, 0.2) 0px 3px 50px;
  margin-left: auto;
}
.sidebar-main {
  padding: 15px 12px 15px 0px;
}
.left-box-shadow-1 {
  box-shadow: -7px 0px 23px 0px rgba(0, 0, 0, 0.18);
}
.rotate-left {
  rotate: 180deg;
}
.w-90per {
  width: 90% !important;
}
.w-80per {
  width: 80% !important;
}
.w-20per {
  width: 20% !important;
}
.w-10per {
  width: 10% !important;
}
.w-6per {
  width: 6% !important;
}
.w-15per {
  width: 15% !important;
}
.w-94per {
  width: 94% !important;
}
.w-85per {
  width: 85% !important;
}
.font-size-18 {
  font-size: 18px !important;
}
.selected-menu {
  border: solid #ef8007;
  border-width: 0px 0px 0px 4px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.18) 0%,
    rgba(255, 255, 255, 0.09) 50%,
    rgba(0, 0, 0, 0) 100%
  );
}

.badgeAT {
  width: fit-content;
}
.picture-uploader-plus {
}

.image-holder-main {
  background-color: rgba(0, 0, 0, 0.06);
  border: dashed 1px rgba(0, 0, 0, 0.4);
}
.lite-border {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.gallery-thumb {
  width: 100%;
  height: auto;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
}
.gal-thumb-delete-butt {
  background-color: white;
  position: absolute;
  right: 1px;
  top: 1px;
  width: 27px;
  height: auto;
  border-radius: 100px;
  border: solid 1px rgba(0, 0, 0, 0.3);
  padding: 2px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);

  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
}
.styled-checkbox {
  width: 30px !important;
  height: 30px !important;
}
.atomic-roundedIconButton {
  border-radius: 100%;
  padding: 5px;
  border: 2px solid #333533;
}
.atomic-input {
  border-radius: 6px;
  border: solid 1px #e5e7eb;
  height: fit-content;
  color: rgba(0, 0, 0, 0.8);
  background-color: #fbfbfb;
}
.modal-header {
  padding: 9px !important;
}
// .row {
//   height: fit-content !important;
// }

.ql-toolbar .ql-snow {
  border: 0px 0px 1px 0px solid #ccc !important;
}
.ql-editor {
  min-height: 18em !important;
  border-radius: 9px !important;
}
.post-imagebox {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.full-dimention {
  height: 100%;
  width: 100%;
}
.post-imagebox-delete {
  position: absolute;
  right: 3px;
  top: 3px;
}
.post-list-item {
  position: relative;
  overflow: hidden; /* Hide overflow from rounded corners */
  height: 100px; /* Set height as needed */
}

.post-list-item__image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 6px; /* Adjust border radius as needed */
}
.product-list-item__image {
  width: 100%; /* or a specific width like 150px */
  height: 0;
  padding-bottom: 100%; /* This makes the height equal to the width, making it a square */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Apply border radius to pseudo-element */
.post-list-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba(0, 0, 0, 0.3); /* Optional: Add a semi-transparent overlay */
}

.rotated-button {
  display: inline-block;
  transform: rotate(-45deg);
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  /* border: 3px solid orange; */
}
*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}
*::-webkit-scrollbar {
  width: 6px !important;
  height: 6px;
}
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(145, 145, 145, 0.579) rgba(133, 133, 133, 0);
}
.pulse {
  $from: #f5f5f5;
  $to: scale-color($from, $lightness: -10%);

  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #cccccc 0%, #f3f3f3 50%, #cccccc 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
}
.row > * {
  flex-shrink: unset;
  width: unset;
  max-width: unset;
  padding-right: unset;
  padding-left: unset;
  margin-top: unset;
}
.mt-sm-3rem {
  margin-top: 0;
}
@media screen and (max-width: 600px) {
  .mt-sm-3rem {
    margin-top: 3rem;
  }
}

.inner-shadow-cus {
  box-shadow: inset -5px -5px 5px rgba(255, 255, 255, 0.2),
    inset 5px 5px 5px rgba(0, 0, 0, 0.1);
}
