@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Dirooz-FD";
  src: local("Dirooz-FD"), url(../public/fonts/Dirooz-FD.ttf) format("truetype");
}
body {
  margin: 0;
  font-family: "Dirooz-FD" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body * {
  font-family: "Dirooz-FD" !important;
}
textarea:focus,
input:focus {
  outline: none !important;
}
.quill * {
  text-align: justify;
}
.swiper-button-prev {
  color: rgba(0, 0, 0, 0.6) !important;
  margin-right: 20px;
}

.swiper-button-next {
  color: rgba(0, 0, 0, 0.6) !important;
  margin-left: 20px;
}
.swiper-pagination-bullet {
  background-color: rgba(0, 0, 0, 0.6) !important;
}
.ql-editor ul li {
  display: flex;
  gap: 10px;
}
.ql-editor ol li {
  display: flex;
  gap: 15px;
}
.ql-editor img {
  margin-left: auto;
  margin-right: auto;
}
.ql-editor iframe {
  margin-left: auto;
  margin-right: auto;
}
