.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-modal {
  background-color: white;
  /* border-radius: 25px; */
}

.ql-container.ql-snow {
  border: none !important;
}
.ql-editor {
  line-height: 33px !important;
  text-align: justify !important;
}
.ql-container {
  font-size: 14px !important;
}
.dotted-background {
  background-image: radial-gradient(#181818 5%, #0000001f 15%);
  background-color: #000;
  position: fixed !important;
  background-position: 0 0, 14px 14px;
  background-size: 16px 16px;
  /* width: 100%; */
}
.promotion-bg {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(239, 239, 239, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}
.tawk-chat-widget {
  display: block !important;
}
